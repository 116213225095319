<template>
  <div :class="{'disabled': isDisabled}" class="selected-project">
    <p class="text">
      {{text}}
    </p>

    <!-- Remove project -->
    <img @click="remove()" src="../../assets/icons/svg/ic_close_secondary.svg" class="remove" alt="remove"/>
  </div>
</template>

<script>
export default {
  name: "ItemWithRemoveButton",
  props: {
    project: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    text: {
      type: String,
      required: true,
    }
  },
  emits: ['remove'],
  setup(props, {emit}) {
    function remove() {
      // If the button is not disabled, emit the event to the parent.
      if (!props.isDisabled) {
        emit('remove', props.project);
      }
    }
    return {
      remove
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";
@import "../../assets/css/forms";

.selected-project {
  background-color: var(--gray-main);
  border-radius: rem(5);
  display: flex;
  justify-content: space-between;
  padding: rem(8) rem(16) rem(8) rem(8);

  .text {
    color: var(--black-light);
  }

  .remove {
    @include hover-active-pointer();
    height: rem(16);
    width: rem(16);
  }
}

// Disabled
.disabled {
  .remove {
    &:hover {
      cursor: default;
    }
  }
}
</style>
