import vuexStore from "../../store";
import {SEARCH_JIRA_PROJECT} from "../../store/jira/actions";
import {CLEAR_JIRA_PROJECTS} from "../../store/jira/mutations";

export default function getJiraHelpers() {
  const store = vuexStore;

  function searchJiraProject(searchTerm) {
    return store.dispatch(SEARCH_JIRA_PROJECT, searchTerm);
  }

  function clearJiraProjects() {
    return store.commit(CLEAR_JIRA_PROJECTS);
  }

  return {searchJiraProject, clearJiraProjects}
}