<template>
  <div>
    <Modal @execute="getDataAndEditProject" :disabled-right-button="!isEditButtonEnabled">
      <template #content>
        <div class="edit-section">
          <p class="section-title">Project details</p>

          <h3 class="basic-input-title input-title">Project name</h3>
          <label for="projectName" class="basic-label">
            <input type="text"
                   id="projectName"
                   name="projectName"
                   placeholder="e.g. Billy"
                   data-cy="project-name"
                   v-model="projectName"
                   class="basic-input"/>
          </label>
        </div>

        <div class="edit-section">
          <p class="section-title">Assign Jira Project(s)</p>

          <!-- Search input with search suggestions -->
          <SearchInputField @search="search"
                            @select-item="selectJiraProject"
                            :search-results="searchResults"
                            auto-search
                            close-after-selection
                            include-suggestions
                            id="searchJiraProject"
                            placeholder="Search for a project"/>

          <!-- Selected Jira projects -->
          <div v-if="selectedJiraProjects.length" class="selected-projects">
            <ItemWithRemoveButton v-for="project in selectedJiraProjects"
                                  :key="project.id"
                                  :text="project.name"
                                  @remove="removeJiraProject"
                                  :project="project"
                                  class="project-item"/>
          </div>
          <p v-else class="no-selected-projects">No projects assigned yet</p>
        </div>
      </template>

      <!-- Extra button for deletion -->
      <template #extraButton>
        <DButton @click.native="toggleDeleteWarning()"
                 text="Delete"
                 type="button"
                 class="button-border modal-button button-delete"/>
      </template>
    </Modal>
  </div>
</template>

<script>
import {computed, ref} from "@vue/composition-api";
import Modal from "@/components/partials/Modal";
import SearchInputField from "@/components/partials/search/SearchInputField";
import ItemWithRemoveButton from "@/components/partials/ItemWithRemoveButton";
import {SEARCH_JIRA_PROJECT} from "@/store/jira/actions";
import {CLEAR_JIRA_PROJECTS} from "@/store/jira/mutations";
import DButton from "../../elements/DButton";
import {MODAL_CONTENT} from "../../../models/ModalContent";
import {DELETE_PROJECT, EDIT_PROJECT} from "../../../store/project/actions";
import {ERR_TYPE} from "@/utils/handlers/ApiErrorMsgHandler";
import {getModalHelpers} from "../../../composables/modalHelper";
import getJiraHelpers from "../../../composables/jira/getJiraHelpers";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";

export default {
  name: "EditProject",
  components: {
    DButton,
    ItemWithRemoveButton,
    SearchInputField,
    Modal,
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  emits: ['edited', 'deleted', 'conflicted'],
  setup(props, {root, emit}) {
    const store = root.$store;

    /** Project name **/
    const projectName = ref(props.project.name);

    /** Search Jira projects **/
    const selectedJiraProjects = ref([...props.project.jiraProjects]);

    function selectJiraProject(item) {
      // Add the clicked item to the selected jira projects.
      selectedJiraProjects.value.push(item);
    }

    function removeJiraProject(item) {
      // Check if the item exists in the jira projects and remove it.
      const index = selectedJiraProjects.value.findIndex(elem => elem.id === item.id);
      selectedJiraProjects.value.splice(index, 1);
    }

    const jiraProjects = computed(() => store.getters.getJiraProjects);
    const searchResults = ref([]);

    function search(searchTerm) {
      if (searchTerm) {
        searchJiraProject(searchTerm).then(response => {
          if (response) {
            // Filter the data without the selectedJiraProjects.
            searchResults.value = jiraProjects.value.filter(item => !item.isInArray(selectedJiraProjects.value));
          }
        });
      } else {
        clearJiraProjects();
      }
    }

    /** Jira project **/
    const {searchJiraProject, clearJiraProjects} = getJiraHelpers();

    /** Edit project **/
    const isEditButtonEnabled = computed(() => selectedJiraProjects.value.length > 0 && projectName.value && hasProjectBeenEdited.value);

    const hasProjectBeenEdited = computed(() => {
      // Check if the name is the same as the current one.
      const isNameUnchanged = projectName.value === props.project.name;

      // Check if the projects are the same as the current one (and the same order).
      const areProjectsSame = selectedJiraProjects.value.every((element, index) => element.id === props.project.jiraProjects[index]?.id);
      const sameAmountOfProjects = props.project.jiraProjects.length === selectedJiraProjects.value.length;

      // Return the value.
      return !isNameUnchanged || !(areProjectsSame && sameAmountOfProjects);
    });

    function getDataAndEditProject() {
      const projects = selectedJiraProjects.value;
      const name = projectName.value;
      const projectId = props.project.id;

      editProject({selectedJiraProjects: projects, projectName: name, projectId}).then(response => {
        if (response) {
          // Close the modal.
          closeModal();

          // Display toast
          ToastHandler.addNew(TOAST_CONTENTS.PROJECT_EDITED(name));

          // Inform the parent that the project has been edited.
          emit('edited');
        } else {

          // If the response is error type = CONFLICT_ORDER_WITH_LOGS_ASSIGNED_TO_JIRA_PROJECT, the user should see a warning about it.
          if (apiError.value === ERR_TYPE.CONFLICT_ORDER_WITH_LOGS_ASSIGNED_TO_JIRA_PROJECT) {
            emit('conflicted');
          }
        }
      });
    }

    function editProject(data) {
      return store.dispatch(EDIT_PROJECT, data);
    }

    /** Not allowed **/
    const apiError = computed(() => store.getters.getApiError);

    /** Delete **/
    function toggleDeleteWarning() {
      const modalContent = MODAL_CONTENT.DELETE_PROJECT(onProjectDeletion);
      setModal(modalContent);
    }

    function onProjectDeletion() {
      deleteProject().then(response => {
        if (response) {
          // Close the delete modal.
          closeModal();

          // Set up the new toast.
          ToastHandler.addNew(TOAST_CONTENTS.PROJECT_DELETED(projectName.value));

          // Create custom event.
          const event = new CustomEvent('project-deleted', {detail: {name: projectName.value}});

          // Dispatch the event.
          document.dispatchEvent(event);
        }
      })
    }

    function deleteProject() {
      const projectId = props.project.id;
      return store.dispatch(DELETE_PROJECT, projectId);
    }

    /** Modal **/
    const {closeModal, setModal} = getModalHelpers();

    function reset() {
      selectedJiraProjects.value = [];
    }

    return {
      apiError,

      /** Project name **/
      projectName,

      /** Search Jira projects **/
      search,
      selectJiraProject,
      selectedJiraProjects,
      removeJiraProject,
      jiraProjects,
      searchResults,

      /** Edit project **/
      isEditButtonEnabled,
      hasProjectBeenEdited,
      getDataAndEditProject,

      /** Delete **/
      toggleDeleteWarning,
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/forms";
@import "../../../assets/css/layout.shared";

// Selected projects
.selected-projects {
  margin-top: rem(16);
}

.no-selected-projects {
  color: var(--gray-light);
  font-family: "Open Sans";
  font-size: rem(16);
  line-height: rem(22);
  margin-top: rem(18);
}

.project-item {
  &:not(:last-child) {
    margin-bottom: rem(8);
  }
}

// Buttons
.button-delete {
  margin-right: auto;
  width: rem(100);
}
</style>
